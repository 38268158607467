<template>
  <v-dialog
    persistent
    max-width="500"
    v-model="modal"
  >
    <v-card>
      <v-card-title>{{ $t('cancellingSubscription') }}</v-card-title>
      <v-card-subtitle>{{ $t('subscriptionNoLongerAvailableOnceCancelledWarning') }}</v-card-subtitle>
      <v-card-actions>
        <v-btn
          :disabled="processing"
          color="red"
          text
          @click="close"
        >
          {{ $t('abort') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="processing"
          :loading="processing"
          @click="confirm"
        >
          {{ $t('confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: 'SubscriptionCancelDialog',
    props: {
      subscription: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      modal: true,
      processing: false
    }),
    methods: {
        close() {
            this.modal = false
            this.$emit('closed')
        },
        confirm() {
          this.processing = true
            this.$store.dispatch('cancelSubscription', this.subscription).then(result =>{
              this.processing = false
              this.close()
              if(result) {
                this.$store.commit('updateActionSuccess', {
                  message: this.$t('success'), 
                  subMessage: this.$t('subscriptionCancelled')
                })
              } else {
                  this.$store.commit('updateActionError', {
                    message: this.$t('unableToCancelSubscription')
                })
              }
            }).catch(error => {
                this.processing = false 
                this.close()
                this.$store.commit('updateActionError', {
                    message: this.$t('unableToCancelSubscription')
                })
            })
        }
    }
}
</script>
