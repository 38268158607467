<template>
  <v-card>
    <v-toolbar
      flat
    >
      <v-toolbar-title>
        {{ subscription.payer.address.name_line }}
      </v-toolbar-title>
      <v-spacer />

      <v-btn
        color="gray"
        small
        outlined
        class="mr-3"
        link
        target="_blank"
        :href="'https://admin.salescloud.is/en/sales/subscriptions/' + this.subscription.uuid + '/edit?destination=sales/subscriptions/overview'"
      >
        {{ $t('edit') }}
      </v-btn>
      <v-btn
        color="orange"
        small
        outlined
        class="mr-3"
        @click="expiration"
      >
        {{ $t('expire') }}
      </v-btn>
      <v-btn
        color="blue"
        small
        outlined
        class="mr-3"
        @click="pause"
        v-if="subscription.active && subscription.paused !== true"
      >
        {{ $t('pause') }}
      </v-btn>
      <v-btn
        color="blue"
        small
        outlined
        class="mr-3"
        @click="resume"
        v-if="subscription.active && subscription.paused === true"
      >
        {{ $t('resume') }}
      </v-btn>
      <v-btn
        color="red"
        small
        outlined
        @click="cancel"
      >
        {{ $t('cancel') }}
      </v-btn>
    </v-toolbar>
    <v-card-text>
      {{ subscription.units }} x {{ subscription.item.title }} @ {{ formattedPrice }}
    </v-card-text>
    <div class="px-2">
      <v-alert
        v-if="subscriptionOverdue && subscriptionState !== null"
        type="warning"
      >
        <p class="ma-0 font-weight-bold">
          {{ subscriptionState.message }}
        </p>
        <p class="ma-0">
          {{ $t('triedToChargeSubscription', { number: subscriptionState.paymentAttemptsCount }) }} {{ $t('lastTried') }} {{ $moment(subscriptionState.lastPaymentAttempt).format('DD/MM/YY') }}.
        </p>
      </v-alert>
    </div>
    <v-tabs
      v-model="tab"
      grow
    >
      <v-tab
        v-for="item in items"
        :key="item.key"
      >
        {{ item.label }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item.key"
      >
        <SubscriptionDetails
          v-if="item.key === 'details'"
          :key="subscription.uuid + ':subscription'"
          :subscription="subscription"
        />
        <OrderList
          v-if="item.key === 'orders'"
          :key="subscription.uuid + ':orders'"
          :load-items-callback="getOrders"
          @selectOrder="selectOrder"
        />
        <PaymentList
          v-if="item.key === 'payments'"
          :key="subscription.uuid + ':payments'"
          :fixed-filters="subscriberPaymentFilters"
          :load-items-callback="getPayments"
          @selectPayment="selectPayment"
        />
      </v-tab-item>
    </v-tabs-items>
    <SubscriptionPauseDialog
      v-if="showSubscriptionPauseDialog"
      :subscription="subscription"
      @closed="closeSubscriptionPauseDialog"
    />
    <SubscriptionCancelDialog
      v-if="showSubscriptionCancelDialog"
      :subscription="subscription"
      @closed="closeSubscriptionCancelDialog"
    />
    <SubscriptionResumeDialog
      v-if="showSubscriptionResumeDialog"
      :subscription="subscription"
      @closed="closeSubscriptionResumeDialog"
    />
    <SubscriptionExpirationDialog
      v-if="showSubscriptionExpirationDialog"
      :subscription="subscription"
      @closed="closeSubscriptionExpirationDialog"
    />
  </v-card>
</template>

<script>
  import SubscriptionExpirationDialog from "../SubscriptionExpirationDialog"
  import SubscriptionResumeDialog from "../SubscriptionResumeDialog"
  import SubscriptionPauseDialog from "../SubscriptionPauseDialog"
  import SubscriptionCancelDialog from "../SubscriptionCancelDialog"
  import OrderList from "../OrderList"
  import PaymentList from "../PaymentList"
  import SubscriptionDetails from "./SubscriptionDetails"

  export default {
    name: 'SubscriptionProfile',
    components: {
      SubscriptionCancelDialog,
      SubscriptionPauseDialog,
      SubscriptionResumeDialog,
      SubscriptionExpirationDialog,
      OrderList,
      PaymentList,
      SubscriptionDetails
    },
    props: {
      subscription: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        tab: {
					key: 'details',
					label: this.$t('details')
				},
        items: [
					{
						key: 'details',
						label: this.$t('details')
					},
					{
						key: 'payments',
						label: this.$t('payments')
					},
					{
						key: 'orders',
						label: this.$t('orders')
					}
        ],
        subscriptionState: null,
        showSubscriptionPauseDialog: false,
        showSubscriptionCancelDialog: false,
        showSubscriptionResumeDialog: false,
        showSubscriptionExpirationDialog: false,
      }
    },
    computed: {
      subscriberPaymentFilters() {
        return [
          {
            key: 'customer',
            value: this.subscription.payer.uuid,
            operator: 'equals'
          }
        ]
      },
      formattedPrice() {

        if(this.recurringPrice.currency_code !== 'ISK') {
          return new Intl.NumberFormat('is-IS', { style: 'currency', currency: this.recurringPrice.currency_code }).format((this.recurringPrice.amount / 100))
        }

        return new Intl.NumberFormat('is-IS', { style: 'currency', currency: this.recurringPrice.currency_code }).format(this.recurringPrice.amount)
      },
      payments() {

        if(this.orders === null) {
          return null
        }

        const payments = []

        for(let i in this.orders) {
          const order = this.orders[i]
          const paymentsInOrder = order.payments

          for(let n in paymentsInOrder) {
            const payment = paymentsInOrder[n]
            payments.push(payment)
          }
        }

        return payments.sort((p1, p2) => p2.created - p1.created)
      },
      recurringPrice() {

        if(this.subscription.recurringPrice !== null && this.subscription.recurringPrice !== undefined) {
          return this.subscription.recurringPrice
        }

        return this.subscription.item.price
      },
      organization() {
        return this.$store.state.organization
      },
      subscriptionOverdue() {
        let overdue = false
        let active = this.subscription !== null && this.subscription.active && this.subscription.paused === false

        if(!active) {
          return overdue
        }

        if(active && this.subscription.expires > 0 && this.subscription.expires < Date.now()) {
          return false
        }

        const subscriptionDiff = Date.now() - this.subscription.nextPayment

        if(subscriptionDiff > 1814400000) {
          overdue = true
        }
        return overdue
      }
    },
    watch: {
      subscription(newValue) {
        this.subscriptionState = null
        this.orders = null

        if(this.subscriptionOverdue === true) {
          this.$store.dispatch('getSubscriptionState', newValue.uuid).then(res => {
            this.subscriptionState = res
          })
        }

        this.getOrders()
      }
    },
    methods: {
      selectPayment(payment) {
        this.$store.commit('updateContextOverlay', {
          'component':  'components/payment/PaymentProfile',
          'props': {
            'payment': payment
          }
        })
      },
      selectOrder(order) {
        this.$store.commit('updateContextOverlay', {
          'component':  'components/order/OrderProfile',
          'props': {
            'entangleKey': order.entangleKey
          }
        })
      },
      getPayments({limit, skip, conditions}) {
        return this.$store.dispatch('getPayments', {limit: limit, conditions})
      },
      getOrders({limit, skip, conditions}) {
        return this.$store.dispatch('getSubscriptionOrders', this.subscription.uuid)
      },
      closeSubscriptionPauseDialog() {
        this.showSubscriptionPauseDialog = false
      },
      closeSubscriptionCancelDialog() {
        this.showSubscriptionCancelDialog = false
      },
      closeSubscriptionResumeDialog() {
        this.showSubscriptionResumeDialog = false
      },
      closeSubscriptionExpirationDialog() {
        this.showSubscriptionExpirationDialog = false
      },
      expiration() {
        this.showSubscriptionExpirationDialog = true
      },
      pause() {
        this.showSubscriptionPauseDialog = true
      },
      resume() {
        this.showSubscriptionResumeDialog = true
      },
      cancel() {
        this.showSubscriptionCancelDialog = true
      }
    },
    mounted() {
      if(this.subscriptionOverdue === true) {
        this.$store.dispatch('getSubscriptionState', this.subscription.uuid).then(res => {
          this.subscriptionState = res
        })
      }
      this.getOrders()
    }
  }
</script>
